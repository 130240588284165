import React, { useState } from "react";

type SubTask = {
  id: number;
  text: string;
  link?: string;
  subtasks?: {
    yes: SubTask[];
    no: SubTask[];
  };
};

type Task = {
  id: number;
  text: string;
  subtasks?: {
    yes: SubTask[];
    no: SubTask[];
  };
};

type LinearTask = {
  id: number;
  text: string;
  link?: string;
};

const TaskOverlay: React.FC = () => {
  // Choice-based task flow
  const [choiceTasks] = useState<Task[]>([
    {
      id: 1,
      text: "Start project planning?",
      subtasks: {
        yes: [
          {
            id: 11,
            text: "Research requirements?",
            subtasks: {
              yes: [{ id: 111, text: "Visit requirements page", link: "/requirements" }],
              no: [{ id: 112, text: "Skip to timeline planning", link: "/timeline" }],
            },
          },
        ],
        no: [
          {
            id: 12,
            text: "Notify the team?",
            subtasks: {
              yes: [{ id: 121, text: "Send email to team", link: "/team-email" }],
              no: [{ id: 122, text: "Postpone notification", link: "/notification" }],
            },
          },
        ],
      },
    },
    {
      id: 2,
      text: "Schedule grocery shopping?",
      subtasks: {
        yes: [{ id: 21, text: "Add items to shopping list", link: "/shopping-list" }],
        no: [{ id: 22, text: "Order groceries online", link: "/order-online" }],
      },
    },
  ]);

  // Straight-path task flow
  const [linearTasks] = useState<LinearTask[]>([
    { id: 1, text: "Define project goals.", link: "/goals" },
    { id: 2, text: "Plan your timeline.", link: "/timeline" },
    { id: 3, text: "Assign tasks to team members.", link: "/team-tasks" },
    { id: 4, text: "Monitor progress.", link: "/monitor-progress" },
    { id: 5, text: "Finalize the project.", link: "/finalize-project" },
  ]);

  // States for decision-making
  const [flowType, setFlowType] = useState<"choice" | "linear" | null>(null);
  const [currentChoicePath, setCurrentChoicePath] = useState<(SubTask | Task)[]>([]);
  const [currentLinearIndex, setCurrentLinearIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  // When user chooses "choice" flow
  const startChoiceFlow = () => {
    setFlowType("choice");
    setCurrentChoicePath([choiceTasks[0]]);
  };

  // When user chooses "linear" flow
  const startLinearFlow = () => {
    setFlowType("linear");
    setCurrentLinearIndex(0);
    setProgress(0);
  };

  // Handle choice-based flow
  const handleChoice = (choice: "yes" | "no") => {
    const currentTask = currentChoicePath[currentChoicePath.length - 1];
    if ("subtasks" in currentTask && currentTask.subtasks) {
      const nextSubtasks = currentTask.subtasks[choice];
      if (nextSubtasks && nextSubtasks.length > 0) {
        setCurrentChoicePath([...currentChoicePath, nextSubtasks[0]]);
        setProgress(progress + 1);
      }
    }
  };

  const handleChoiceBack = () => {
    if (currentChoicePath.length > 1) {
      setCurrentChoicePath(currentChoicePath.slice(0, -1));
      setProgress(progress - 1);
    }
  };

  // Handle linear flow
  const handleLinearNext = () => {
    if (currentLinearIndex < linearTasks.length - 1) {
      setCurrentLinearIndex((prev) => prev + 1);
      setProgress(progress + 1);
    }
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-100 transition-all duration-300">
      {/* Progress Indicator */}
      {flowType && (
        <div className="w-full max-w-xl mb-4">
          <div className="relative h-2 bg-gray-300 rounded-full overflow-hidden">
            <div
              className="absolute top-0 left-0 h-full bg-blue-500 transition-all duration-300"
              style={{
                width: `${
                  flowType === "choice"
                    ? (progress / (choiceTasks.length + 2)) * 100
                    : (progress / linearTasks.length) * 100
                }%`,
              }}
            ></div>
          </div>
        </div>
      )}

      {/* Flow Decision */}
      {!flowType && (
        <div className="w-96 p-6 bg-white shadow-lg rounded-lg text-center">
          <h2 className="text-lg font-bold mb-4">Choose your flow</h2>
          <div className="flex justify-around">
            <button
              onClick={startChoiceFlow}
              className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
            >
              Choice-based
            </button>
            <button
              onClick={startLinearFlow}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Straight path
            </button>
          </div>
        </div>
      )}

      {/* Choice-based Flow */}
      {flowType === "choice" && currentChoicePath.length > 0 && (
        <div className="w-96 p-6 bg-white shadow-lg rounded-lg text-center">
          <h2 className="text-lg font-bold mb-4">
            {currentChoicePath[currentChoicePath.length - 1]?.text}
          </h2>

          {"subtasks" in currentChoicePath[currentChoicePath.length - 1] ? (
  <div className="flex justify-around">
    <button
      onClick={() => handleChoice("yes")}
      className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
    >
      Yes
    </button>
    <button
      onClick={() => handleChoice("no")}
      className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
    >
      No
    </button>
  </div>
) : "link" in (currentChoicePath[currentChoicePath.length - 1] as SubTask) ? (
  <a
    href={(currentChoicePath[currentChoicePath.length - 1] as SubTask)?.link}
    target="_blank"
    rel="noopener noreferrer"
    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
  >
    Go to {currentChoicePath[currentChoicePath.length - 1]?.text}
  </a>
) : null}

          <button
            onClick={handleChoiceBack}
            className="mt-4 text-sm text-gray-500 hover:underline"
          >
            Go Back
          </button>
        </div>
      )}

      {/* Linear Flow */}
      {flowType === "linear" && (
        <div className="w-96 p-6 bg-white shadow-lg rounded-lg text-center">
          <h2 className="text-lg font-bold mb-4">{linearTasks[currentLinearIndex]?.text}</h2>

          {linearTasks[currentLinearIndex]?.link ? (
            <a
              href={linearTasks[currentLinearIndex]?.link}
              target="_blank"
              rel="noopener noreferrer"
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Go to {linearTasks[currentLinearIndex]?.text}
            </a>
          ) : (
            <button
              onClick={handleLinearNext}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Next
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default TaskOverlay;
